import { injectable } from 'inversify-props';
import { classToPlain } from 'class-transformer';
import { cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import BaseService from '@/services/base-service';
import ConversationModel from '@/models/crm/conversation.model';
import { RequestConfig } from '@/interfaces/request-config.interface';
import ConversationMessageModel from '@/models/crm/conversation-message.model';
import { ConversationMessageTypeEnum } from '@/enums/crm/conversation-message-type.enum';
import ConversationUserPermissionsModel from '@/models/crm/conversation-user-permissions.model';
import UserModel from '@/models/user.model';
import ConversationLogModel from '@/models/crm/conversation-log.model';
import ConversationContactModel from '@/models/crm/conversation-contact.model';
import ConversationTemplateModel from '@/models/crm/conversation-template.model';
import { ConversationTypeEnum } from '@/enums/crm/conversation-type.enum';
import { IKeyValue } from '@/interfaces/key-value.interface';
import OriginModel from '@/models/crm/origin.model';
import HistoryTypeModel from '@/models/crm/history-type.model';
import ConversationUploadModel from '@/models/crm/conversation-upload.model';
import CommServiceTokenModel from '@/models/comm-service-token.model';
import ConversationMessageMediaModel from '@/models/crm/conversation-message-media.model';
import ConversationMessageMediaInfoModel from '@/models/crm/conversation-message-media-info.model';
import ConversationDepartmentModel from '../../models/crm/conversation-department.model';
import { PaginationModel } from '../../models/pagination.model';
import ConversationQuickAnswerModel from '@/models/crm/conversation-quick-answer.model';
import ConversationSendOptinModel from '@/models/crm/conversation-send-optin';
import ConversationPreviousProtocolMessageModel from '@/models/crm/conversation-previous-protocol-message.model';

@injectable()
export default class ConversationService extends BaseService {
  private commServiceToken: CommServiceTokenModel | null = null;

  private commServiceTokenExpiration: dayjs.Dayjs | null = null;

  private queuedCommServiceTokenRequest: Promise<CommServiceTokenModel> | null = null;

  async sendTextMessage(
    conversation: ConversationModel,
    message: string,
    checkIsOpen = false,
  ): Promise<ConversationMessageModel> {
    const payload = {
      tipoMensagem: 'text',
      numeroWhatsapp: conversation.numeroWhatsapp,
      text: { body: message },
      checkIsOpen,
    };

    return (await this.httpService.post(
      ConversationMessageModel,
      `${this.getApiPath()}/crm/conversa/${conversation.id}/send-message`,
      payload,
    )) as ConversationMessageModel;
  }

  async sendTemplateMessage(
    conversation: ConversationModel,
    template: ConversationTemplateModel,
    checkIsOpen = false,
    variables: Map<string, string>,
  ): Promise<ConversationMessageModel> {
    const payload = {
      tipoMensagem: 'template',
      numeroWhatsapp: conversation.numeroWhatsapp,
      template,
      variaveisTemplate: classToPlain(cloneDeep(variables)),
      checkIsOpen,
    };

    return (await this.httpService.post(
      ConversationMessageModel,
      `${this.getApiPath()}/crm/conversa/${conversation.id}/send-message`,
      payload,
    )) as ConversationMessageModel;
  }

  async sendMediaMessage(
    conversation: ConversationModel,
    type: ConversationMessageTypeEnum,
    mediaId: string,
    caption: string,
    filename: string,
  ): Promise<ConversationMessageModel> {
    const payload: IKeyValue<string | IKeyValue> = {
      tipoMensagem: type,
      numeroWhatsapp: conversation.numeroWhatsapp,
      media: {
        id: mediaId,
      },
    };

    if (type === ConversationMessageTypeEnum.Image || type === ConversationMessageTypeEnum.Video) {
      (payload.media as IKeyValue).caption = caption;
    }

    if (type === ConversationMessageTypeEnum.Document) {
      (payload.media as IKeyValue).filename = filename;
    }

    return (await this.httpService.post(
      ConversationMessageModel,
      `${this.getApiPath()}/crm/conversa/${conversation.id}/send-message`,
      payload,
    )) as ConversationMessageModel;
  }

  async createConversation(
    conversation: ConversationModel,
    template: ConversationTemplateModel | null,
    variables: Map<string, string> | null,
    apenasCriar: boolean | null,
  ): Promise<ConversationModel> {
    // Verifica se tem a chave cliente, pois pode existir prospects que vem a chave cliente ao inves da chave prospect
    const isProspect = conversation.cliente && conversation.cliente.tipo === 'Prospect';
    const clientProspectId = isProspect ? conversation.cliente.codCliente : null;

    const payload = {
      numeroWhatsapp: conversation.numeroWhatsapp,
      numeroContato: conversation.numeroContato,
      nomeContato: conversation.nomeContato,
      cnpj: conversation.cnpj === '' ? null : conversation.cnpj,
      departamento: { id: conversation.departamento?.id },
      atendente: { id: conversation.atendente?.id },
      contato: conversation.contato ? { id: conversation.contato.id } : null,
      template: { id: template?.id },
      variaveisTemplate: classToPlain(cloneDeep(variables)),
      tipo: conversation.tipo,
      prospectId: conversation.prospect ? conversation.prospect.codProspect : clientProspectId,
    };

    const addToUrl = apenasCriar ? '?apenasCriar=true' : '';
    return (await this.httpService.post(
      ConversationModel,
      `${this.getApiPath()}/crm/conversa${addToUrl}`,
      payload,
    )) as ConversationModel;
  }

  async openConversation(conversation: ConversationModel,
    attendant: UserModel,
    skipSendOptin = false): Promise<ConversationModel> {
    const payload = {
      atendente: attendant,
      skipCheckExpiration: true,
      skipSendOptin,
    };

    return (await this.httpService.post(
      ConversationModel,
      `${this.getApiPath()}/crm/conversa/${conversation.id}/open`,
      payload,
    )) as ConversationModel;
  }

  async closeConversation(
    conversation: ConversationModel,
    mistake: boolean,
    justified: boolean,
    description: string,
    origin?: OriginModel,
    historyType?: HistoryTypeModel,
    consolidateSale?: boolean,
    contatoId?: number,
  ): Promise<ConversationModel> {
    const payload: IKeyValue<string | boolean | unknown> = {
      descricao: description,
    };

    if (mistake) {
      payload.engano = true;
    } else if (justified) {
      payload.outraJustificativaFechamento = true;
    } else {
      payload.origem = { id: origin?.id };
      payload.tipoHistorico = { id: historyType?.id };
      payload.efetuouVenda = historyType?.flagPassivoVenda ? !!consolidateSale : false;
      payload.contatoId = contatoId;
    }

    return (await this.httpService.post(
      ConversationModel,
      `${this.getApiPath()}/crm/conversa/${conversation.id}/close`,
      payload,
    )) as ConversationModel;
  }

  async closeConversations(
    conversations: Array<number>,
    mistake: boolean,
    justified: boolean,
    description: string,
    origin?: OriginModel,
    historyType?: HistoryTypeModel,
    consolidateSale?: boolean,
  ): Promise<number[]> {
    const config: RequestConfig = {
      params: {
        descricao: description.replaceAll('[', '').replaceAll(']', ''),
        engano: mistake,
        outraJustificativa: justified,
        origem: mistake ? null : origin?.id,
        tipoHistorico: mistake ? null : historyType?.id,
        efetuouVenda: historyType?.flagPassivoVenda ? consolidateSale : false,
      },
    };

    return (await this.httpService.post(
      Number,
      `${this.getApiPath()}/crm/conversa/close-conversations`,
      conversations,
      config,
    )) as number[];
  }

  async transferConversation(
    conversation: ConversationModel,
    newAttendant: number,
    mistake: boolean,
    justified: boolean,
    description: string,
    origin?: OriginModel,
    historyType?: HistoryTypeModel,
    consolidateSale?: boolean,
    contatoId?: number,
  ): Promise<ConversationModel> {
    const payload: IKeyValue<string | boolean | unknown> = {
      descricao: description,
      novoAtendente: { id: newAttendant },
    };

    if (mistake) {
      payload.engano = true;
    } else if (justified) {
      payload.outraJustificativaFechamento = true;
    } else {
      payload.origem = { id: origin?.id };
      payload.tipoHistorico = { id: historyType?.id };
      payload.efetuouVenda = historyType?.flagPassivoVenda ? !!consolidateSale : false;
      payload.contatoId = contatoId;
    }

    let { id } = conversation;

    if (!id) {
      id = conversation[0].id;
    }

    return (await this.httpService.post(
      ConversationModel,
      `${this.getApiPath()}/crm/conversa/${id}/transfer`,
      payload,
    )) as ConversationModel;
  }

  async setClientProspectConversation(conversation: ConversationModel,
    prospectId: number | null = null): Promise<ConversationModel> {
    const payload: IKeyValue<string | number | null> = {
      tipo: conversation.tipo,
      contatoId: conversation.contato?.id,
    };

    if (conversation.tipo === ConversationTypeEnum.Prospect && prospectId) {
      payload.prospectId = prospectId;
    } else {
      payload.cnpj = conversation.cnpj;
    }

    return (await this.httpService.post(
      ConversationModel,
      `${this.getApiPath()}/crm/conversa/${conversation.id}/set-client`,
      payload,
    )) as ConversationModel;
  }

  async changeDepartment(
    conversation: ConversationModel,
    newDep: ConversationDepartmentModel,
  ): Promise<ConversationModel> {
    return (await this.httpService.post(
      ConversationModel,
      `${this.getApiPath()}/crm/conversa/${conversation.id}/change-department`,
      newDep,
    )) as ConversationModel;
  }

  async checkContactNumber(contactNumber: string): Promise<ConversationContactModel[]> {
    return (await this.httpService.post(
      ConversationContactModel,
      `${this.getApiPath()}/crm/conversa/check-contact`,
      contactNumber,
    )) as ConversationContactModel[];
  }

  async attachContact(conversation: ConversationModel): Promise<ConversationModel> {
    const payload: IKeyValue<string | number> = {
      tipo: conversation.tipo,
      contatoId: conversation.contato.id,
    };

    if (conversation.tipo === ConversationTypeEnum.Prospect) {
      payload.prospectId = parseInt(conversation.prospect?.codProspect, 10);
    } else {
      payload.cnpj = conversation.cnpj;
    }

    return (await this.httpService.post(
      ConversationModel,
      `${this.getApiPath()}/crm/conversa/${conversation.id}/attach-contact`,
      payload,
    )) as ConversationModel;
  }

  async getOnHoldConversations(attendantId?: number): Promise<ConversationModel[]> {
    const config: RequestConfig = {
      params: {
        status: 1,
        ignoreDepartment: false,
      },
    };

    if (attendantId) {
      config.params.attendant = attendantId;
    }

    return (await this.httpService.get(
      ConversationModel,
      `${this.getApiPath()}/crm/conversa/list`,
      config,
    )) as ConversationModel[];
  }

  async getOpenConversations(attendantId?: number,
    ignoreUserIdForConversation = false,
    ignoreDepartment = true): Promise<ConversationModel[]> {
    const config: RequestConfig = {
      params: {
        status: 2,
        ignoreDepartment,
        ignoreUserIdForConversation,
      },
    };

    if (attendantId) {
      config.params.attendant = attendantId;
    }

    return (await this.httpService.get(
      ConversationModel,
      `${this.getApiPath()}/crm/conversa/list`,
      config,
    )) as ConversationModel[];
  }

  async getCloseConversations(whatsappNumber?: string): Promise<ConversationModel[]> {
    const config: RequestConfig = {
      params: {
        status: 4,
        ignoreDepartment: false,
      },
    };

    if (whatsappNumber) {
      config.params.whatsappNumber = whatsappNumber;
    }

    return (await this.httpService.get(
      ConversationModel,
      `${this.getApiPath()}/crm/conversa/list`,
      config,
    )) as ConversationModel[];
  }

  async queryConversations(
    page: number,
    limit: number,
    filter: unknown,
    orderBy?: string,
  ): Promise<PaginationModel<ConversationModel>> {
    const config: RequestConfig = {
      params: {
        page,
        limit,
      },
    };

    if (orderBy) {
      config.params.orderBy = orderBy;
    }

    return (await this.httpService.post(
      PaginationModel,
      `${this.getApiPath()}/crm/conversa/query`,
      filter,
      config,
    )) as PaginationModel<ConversationModel>;
  }

  async getConversation(id: number, loadMessages = true): Promise<ConversationModel> {
    const config: RequestConfig = {
      params: {
        loadMessages,
      },
    };

    return (await this.httpService.get(
      ConversationModel,
      `${this.getApiPath()}/crm/conversa/${id}`,
      config,
    )) as ConversationModel;
  }

  async getConversationByWaID(waId: string): Promise<ConversationModel> {
    return (await this.httpService.get(
      ConversationModel,
      `${this.getApiPath()}/crm/conversa/consulta/waid/${waId}`,
    )) as ConversationModel;
  }

  async getConversationLogs(id: number): Promise<ConversationLogModel[]> {
    return (await this.httpService.get(
      ConversationLogModel,
      `${this.getApiPath()}/crm/conversa/logs?conversation=${id}`,
    )) as ConversationLogModel[];
  }

  async getConversationMessages(
    waConversationId: number,
    onlyLastMessage = false,
  ): Promise<ConversationMessageModel[]> {
    const config: RequestConfig = {
      params: {
        onlyLastMessage,
      },
    };

    return (await this.httpService.get(
      ConversationMessageModel,
      `${this.getApiPath()}/crm/conversa/${waConversationId}/messages`,
      config,
    )) as ConversationMessageModel[];
  }

  async getOnlyLastConversationMessages(
    listaWaConversationId: number[],
  ): Promise<ConversationMessageModel[]> {
    return (await this.httpService.post(
      ConversationMessageModel,
      `${this.getApiPath()}/crm/conversa/last-messages`,
      listaWaConversationId,
    )) as ConversationMessageModel[];
  }

  async getUserPermissions(): Promise<ConversationUserPermissionsModel> {
    return (await this.httpService.get(
      ConversationUserPermissionsModel,
      `${this.getApiPath()}/crm/conversa/user-permissions`,
    )) as ConversationUserPermissionsModel;
  }

  async getTemplates(onlyActive = true): Promise<ConversationTemplateModel[]> {
    const config: RequestConfig = {
      params: {
        onlyActive,
      },
    };
    return (await this.httpService.get(
      ConversationTemplateModel,
      `${this.getApiPath()}/crm/conversa-template/list`,
      config,
    )) as ConversationTemplateModel[];
  }

  async sendOptin(conversation: ConversationModel, forceToSend = false): Promise<ConversationSendOptinModel> {
    const url = `${this.getApiPath()}/crm/conversa/optin?forceToSend=${forceToSend}&conversationId=${conversation.id}`;
    return (await this.httpService.post(
      ConversationSendOptinModel,
      url, {},
    )) as ConversationSendOptinModel;
  }

  async checkIfOptinAlreadySent(whatsappNumber: string, clientId: string): Promise<ConversationSendOptinModel> {
    const params = `?whatsappNumber=${whatsappNumber}&clientId=${clientId}`;
    const url = `${this.getApiPath()}/crm/conversa/optin/validar${params}`;
    return (await this.httpService.post(
      ConversationSendOptinModel,
      url, {},
    )) as ConversationSendOptinModel;
  }

  async getQuickAnswers(onlyActive = true): Promise<ConversationQuickAnswerModel[]> {
    const config: RequestConfig = {
      params: {
        onlyActive,
      },
    };
    return (await this.httpService.get(
      ConversationQuickAnswerModel,
      `${this.getApiPath()}/crm/conversa-respostas-rapidas`,
      config,
    )) as ConversationQuickAnswerModel[];
  }

  async getAttendants(newRegister = false): Promise<UserModel[]> {
    const config: RequestConfig = {
      params: { isNewRegister: newRegister },
    };
    return (await this.httpService.get(
      UserModel,
      `${this.getApiPath()}/crm/conversa/attendants`,
      config,
    )) as UserModel[];
  }

  async getDepartments(onlyActive = false): Promise<ConversationDepartmentModel[]> {
    const config: RequestConfig = {
      params: { onlyActive },
    };
    return (await this.httpService.get(
      ConversationDepartmentModel,
      `${this.getApiPath()}/crm/conversa-departamento/list`,
      config,
    )) as ConversationDepartmentModel[];
  }

  async getBtnCloseNotSavingContact(): Promise<string> {
    return (await this.httpService.get(
      String,
      `${this.getApiPath()}/crm/conversa/close-not-saving-contact-btn`,
    )) as string;
  }

  async uploadMedia(type: ConversationMessageTypeEnum, file: File | Blob): Promise<ConversationUploadModel> {
    const config: RequestConfig = {
      headers: { 'Content-Type': file.type },
    };

    return (await this.httpService.post(
      ConversationUploadModel,
      `${this.getApiPath()}/crm/conversa/upload-media/${type}`,
      file,
      config,
    )) as ConversationUploadModel;
  }

  async uploadMediaToCommService(
    credentials: CommServiceTokenModel,
    type: ConversationMessageTypeEnum,
    file: File | Blob,
  ): Promise<ConversationUploadModel> {
    const config: RequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${credentials.token}`,
      },
    };

    const formData = new FormData();
    formData.append('file', file);

    return (await this.httpService.post(
      ConversationUploadModel,
      `${credentials.apiUrl}/whatsapp/media/multipart/${type}`,
      formData,
      config,
    )) as ConversationUploadModel;
  }

  async getMediaInfoFromCommService(
    credentials: CommServiceTokenModel,
    checksum: string,
  ): Promise<ConversationMessageMediaModel> {
    const config: RequestConfig = {
      headers: {
        Authorization: `Bearer ${credentials.token}`,
      },
    };

    return (await this.httpService.get(
      ConversationMessageMediaModel,
      `${credentials.apiUrl}/whatsapp/media/${checksum}/media-info`,
      config,
    )) as ConversationMessageMediaModel;
  }

  async getMediaDownloadUrlFromCommService(
    credentials: CommServiceTokenModel,
    mediaId: string,
  ): Promise<ConversationMessageMediaInfoModel> {
    const config: RequestConfig = {
      headers: {
        Authorization: `Bearer ${credentials.token}`,
      },
    };

    return (await this.httpService.get(
      ConversationMessageMediaInfoModel,
      `${credentials.apiUrl}/whatsapp/media/${mediaId}/download-url`,
      config,
    )) as ConversationMessageMediaInfoModel;
  }

  async getCachedCommServiceToken(): Promise<CommServiceTokenModel> {
    const isTokenExpired = !this.commServiceTokenExpiration || this.commServiceTokenExpiration?.isBefore(dayjs());
    if (!this.commServiceToken || isTokenExpired) {
      if (!this.queuedCommServiceTokenRequest) {
        this.queuedCommServiceTokenRequest = this.getCommServiceToken();
      }
      try {
        this.commServiceToken = await this.queuedCommServiceTokenRequest;
      } catch (error) {
        return new CommServiceTokenModel();
      } finally {
        this.queuedCommServiceTokenRequest = null;
        this.commServiceTokenExpiration = dayjs().add(2, 'hour');
      }
    }

    return this.commServiceToken;
  }

  async getCommServiceToken(): Promise<CommServiceTokenModel> {
    return (await this.httpService.get(
      CommServiceTokenModel,
      `${this.getApiPath()}/crm/conversa/comm-service-token`,
    )) as CommServiceTokenModel;
  }

  async markConversationMessagesAsRead(id: number, ids: number[]): Promise<number> {
    return (await this.httpService.post(
      Number,
      `${this.getApiPath()}/crm/conversa/${id}/mark-messages-as-read`,
      ids,
    )) as number;
  }

  private queuedMediaDownloadUrlRequest = new Map<string, Promise<ConversationMessageMediaInfoModel>>();

  async getMediaUrl(
    credentials: CommServiceTokenModel,
    message: ConversationMessageModel,
    allowDataUri = false,
  ): Promise<string | null> {
    if (!message?.content?.data) {
      return null;
    }

    const { data } = message.content;
    const dataUri = data?.temporary?.dataUri;
    if (dataUri && allowDataUri) {
      return dataUri as string;
    }

    const mediaId = ConversationService.extractMediaId(message);

    if (!mediaId) {
      return null;
    }

    if (mediaId) {
      if (!this.queuedMediaDownloadUrlRequest.get(mediaId)) {
        this.queuedMediaDownloadUrlRequest.set(mediaId, this.getMediaDownloadUrlFromCommService(credentials, mediaId));
      }

      try {
        const existent = await this.queuedMediaDownloadUrlRequest.get(mediaId);

        if (!existent) {
          this.queuedMediaDownloadUrlRequest.delete(mediaId);
        }

        if (existent && existent.downloadUrl && existent?.media?.destination === 's3') {
          // returns S3 url instead Comm service url;
          return existent.downloadUrl;
        }
      } catch (error) {
        // do nothing
      }
    }

    const downloadEndpoint = `${credentials.apiUrl}/whatsapp/media/${credentials.code}/`;
    return ConversationService.generateMediaUrl(downloadEndpoint, message);
  }

  getMediaUrlToIgs(message: ConversationMessageModel, allowDataUri = false): string | null {
    if (!message?.content?.data) {
      return null;
    }

    const { data } = message.content;
    const dataUri = data?.temporary?.dataUri;
    if (dataUri && allowDataUri) {
      return dataUri as string;
    }

    const downloadEndpoint = `${this.getApiPath()}/crm/conversa/download-media/`;
    return ConversationService.generateMediaUrl(downloadEndpoint, message);
  }

  private static extractMediaId(message: ConversationMessageModel): string | null {
    if (!message?.content?.data) {
      return null;
    }

    const { data } = message.content;

    let metadata: IKeyValue<string | string[] | IKeyValue<string | string[]>[]> | null = null;

    switch (message.type.code) {
      case ConversationMessageTypeEnum.Image:
        metadata = data.image;
        break;
      case ConversationMessageTypeEnum.Sticker:
        metadata = data.sticker;
        break;
      case ConversationMessageTypeEnum.Document:
        metadata = data.document;
        break;
      case ConversationMessageTypeEnum.Audio:
        metadata = data.audio;
        break;
      case ConversationMessageTypeEnum.Video:
        metadata = data.video;
        break;
      case ConversationMessageTypeEnum.Voice:
        metadata = data.voice;
        break;
      default:
        break;
    }

    if (metadata?.Id) {
      return metadata.Id as string;
    }

    return null;
  }

  private static generateMediaUrl(downloadEndpoint: string, message: ConversationMessageModel): string | null {
    if (message.content && message.content.data) {
      const { data } = message.content;

      switch (message.type.code) {
        case ConversationMessageTypeEnum.Image:
          return ConversationService.parseMediaUrlByType(downloadEndpoint, data.image, 'image/jpeg');
        case ConversationMessageTypeEnum.Sticker:
          return ConversationService.parseMediaUrlByType(downloadEndpoint, data.sticker, 'image/jpeg');
        case ConversationMessageTypeEnum.Document:
          return ConversationService.parseMediaUrlByType(downloadEndpoint, data.document, 'application/octet-stream');
        case ConversationMessageTypeEnum.Audio:
          return ConversationService.parseMediaUrlByType(downloadEndpoint, data.audio, 'audio/mpeg');
        case ConversationMessageTypeEnum.Video:
          return ConversationService.parseMediaUrlByType(downloadEndpoint, data.video, 'application/octet-stream');
        case ConversationMessageTypeEnum.Voice:
          return ConversationService.parseMediaUrlByType(downloadEndpoint, data.voice, 'audio/mpeg');
        default:
          break;
      }
    }

    return null;
  }

  private static parseMediaUrlByType(
    apiUrl: string,
    metadata: IKeyValue<string | string[] | IKeyValue<string | string[]>[]>,
    mimeType: string,
  ): string | null {
    if (!metadata?.Id) {
      return null;
    }

    const downloadEndpoint = apiUrl;
    const validMimeType = metadata?.Mime_Type || mimeType;
    const extension = ConversationService.extractExtension(validMimeType as string, metadata?.Filename as string);

    let result = `${downloadEndpoint}${metadata.Id}`;
    if (extension) {
      result += `.${extension}`;
    }
    result += `?contentType=${validMimeType}`;

    return result;
  }

  private static extractExtension(mimeType: string, filename?: string): string | null {
    if (filename) {
      const attrs = filename.split('.');
      if (attrs[1]) {
        return attrs[1];
      }
    }

    const splittedMimeType = mimeType.split(';');

    switch (splittedMimeType[0]) {
      case 'image/jpeg':
      case 'image/jpg':
        return 'jpg';
      case 'image/gif':
        return 'gif';
      case 'image/svg+xml':
        return 'svg';
      case 'audio/ogg':
        return 'ogg';
      case 'audio/mpeg':
        return 'mp3';
      case 'audio/mp4':
        return 'mp4';
      case 'audio/aac':
        return 'aac';
      case 'audio/x-wav':
        return 'wav';
      case 'video/mpeg':
        return 'mpeg';
      case 'video/mp4':
        return 'mp4';
      case 'video/quicktime':
        return 'mov';
      case 'application/msword':
        return 'doc';
      case 'application/pdf':
        return 'pdf';
      case 'application/rtf':
        return 'rtf';
      case 'application/vnd.ms-excel':
        return 'xls';
      case 'application/vnd.ms-powerpoint':
        return 'ppt';
      case 'application/x-tar':
        return 'tar';
      case 'application/zip':
        return 'zip';
      default:
        return null;
    }
  }

  static generateConversationReportExportFilename(date: Date): string {
    return `Conversas_${dayjs(date).format('YYYY-MM-DD_HH-mm-ss')}.xlsx`;
  }

  public async loadPreviousProtocol(contactId: number | null, clientId: string, whatsappNumber: string, offset: number)
    : Promise<ConversationPreviousProtocolMessageModel> {
    const config: RequestConfig = {
      params: {
        contactId,
        clientId,
        whatsappNumber,
        offset,
      },
    };

    return (await this.httpService.get(
      ConversationPreviousProtocolMessageModel,
      `${this.getApiPath()}/crm/conversa/protocolo-anterior`,
      config,
    )) as ConversationPreviousProtocolMessageModel;
  }

  async getConversationContact(idContact: number): Promise<number> {
    const config: RequestConfig = {
      params: {
        idContact,
      },
    };

    return (await this.httpService.get(
      Number,
      `${this.getApiPath()}/crm/conversa/conversation-contact`,
      config,
    )) as number;
  }
}
